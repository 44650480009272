@tailwind base;
@tailwind components;
@tailwind utilities;
@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@200;300;400;500;600;700;800;900&display=swap");
::-webkit-scrollbar {
  width: 5px;
}

::-webkit-scrollbar-track {
  background: #424242; /* Track color */
}

::-webkit-scrollbar-thumb {
  background-color: #990002; /* Thumb color */
  border-radius: 99px;
}
