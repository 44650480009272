.container {
  padding: 75px 0;
  margin: 0 auto;
  width: 1140px;
}

.item {
  flex: 1;
  height: 100%;
  background-position: center;
  background-size: cover;
  background-repeat: none;
  transition: flex 0.8s ease;
  &:hover {
    flex: 3;
  }
}

.gallery-wrap {
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 75vh;
}

.item-0 {
  background-image: url("./assets/gallery/vertical/img0.webp");
}
.item-1 {
  background-image: url("./assets/gallery/vertical/img1.webp");
}
.item-2 {
  background-image: url("./assets/gallery/vertical/img2.webp");
}

.item-3 {
  background-image: url("./assets/gallery/vertical/img3.webp");
}
.item-4 {
  background-image: url("./assets/gallery/vertical/img4.webp");
}
.item-5 {
  background-image: url("./assets/gallery/vertical/img5.webp");
}
.item-6 {
  background-image: url("./assets/gallery/vertical/img6.webp");
}
.item-7 {
  background-image: url("./assets/gallery/vertical/img7.webp");
}
